export const COMPANY_LINKS = {
    LIMEX: 'https://tb-m.com/products/products-limex/',
    CIRCILE_X: 'https://tb-m.com/business/circulation-material/',
    MAAR: 'https://service.maar.app/',
    ZAIMA: 'https://zaima.in/',
    CAREERS: 'https://tb-m.com/recruit/',
    TBM: 'https://tb-m.com/company/',
    CONTACT: 'https://share.hsforms.com/1Ea-QL1UWR2-iJNOadEWe2Aczni6',
    REQUEST_TO_ADD_NEW_SITE: 'https://share.hsforms.com/1DhhTLInwRZyusFtdlWeJJAczni6',

    // plans
    TAYORI: 'https://share.hsforms.com/1KDeOAu_6TsmVUtbIUG3m4wczni6',
    TAYORI_FAQ: 'https://tayori.com/faq/f2ccc290ed5b52c7ce61595f66aa3b1925904aa8',
}
